import React from "react";

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import PropertyCardButton from "./PropertyCardButton";

const renderList = (items) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const PropertyCard = (props) => {
  const cardContent = () => {
    if (props.text.points && props.text.points.length > 0) {
      return renderList(props.text.points);
    }
    return <div>{props.text.text}</div>;
  };

  return (
    <Card className="w-100 text-dark">
      <div className="p-2 d-flex flex-column flex-nowrap justify-content-start align-items-start">
        <div className="w-100 mt-auto mb-4">
          <PropertyCardButton
            text={props.button.text}
            buttonType={props.button.buttonType}
          />
        </div>
        <div style={props.text.style}>
          <div>Find out:</div>
          {cardContent()}
        </div>
      </div>
    </Card>
  );
};

PropertyCard.propTypes = {
  text: PropTypes.shape({
    text: PropTypes.string,
    style: PropTypes.object,
    points: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

export default PropertyCard;
