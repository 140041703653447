import React from "react";

import Img from "gatsby-image";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import withDocumentData from "components/ContentGroup/DocumentWrapper";
import DocButtonLink from "components/Links/DocButtonLink";

import withImageData from "./common";

const CardWithDocLinkButton = withImageData((props) => {
  const buttonProps = props.button.buttonProps;

  const path = props.getDocumentURLByName(
    props.documents,
    buttonProps.requiredDocument
  );

  const buildImage = () => {
    if (props.type === "icon") {
      return (
        <Card.Img
          as={props.icon.type}
          size={props.icon.size}
          color={props.icon.color}
          className={props.icon.className}
          variant="top"
        />
      );
    }

    const image = props.imageData.images.edges.find(({ node }) => {
      return node.name === props.fileName;
    });
    return image ? (
      <Card.Img
        as={Img}
        fluid={image.node.childImageSharp.fluid}
        style={props.imgStyle}
        variant="top"
        alt={props.altText || image.node.name}
      />
    ) : (
      <>{props.fileName}</>
    );
  };

  return (
    <Card>
      <a href={path} target="_blank" rel="noopener noreferrer">
        {buildImage()}
      </a>
      <Card.Body className="d-flex flex-column">
        <Card.Title style={props.title.style}>{props.title.text}</Card.Title>

        {props.subtitle && (
          <Card.Subtitle style={props.subtitle.style}>
            {props.subtitle.text}
          </Card.Subtitle>
        )}

        {props.text && (
          <Card.Text style={props.text.style}>{props.text.text}</Card.Text>
        )}

        {props.button && (
          <div className="mt-auto">
            <DocButtonLink
              className={buttonProps.className}
              variant={buttonProps.variant}
              requiredDocument={buttonProps.requiredDocument}
              isExternal
            >
              {props.button.text}
            </DocButtonLink>
          </div>
        )}
      </Card.Body>
    </Card>
  );
});

CardWithDocLinkButton.propTypes = {
  type: PropTypes.oneOf(["image", "icon"]).isRequired,
  fileName: PropTypes.string,
  altText: PropTypes.string,
  imgStyle: PropTypes.object,
  icon: PropTypes.shape({
    type: PropTypes.elementType.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  }),
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  button: PropTypes.shape({
    text: PropTypes.string,
    buttonProps: PropTypes.shape({
      requiredDocument: PropTypes.string,
      variant: PropTypes.string,
      className: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

CardWithDocLinkButton.defaultProps = {
  type: "image",
};

export default withDocumentData(CardWithDocLinkButton);
