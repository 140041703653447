import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { object, string, number } from "yup";

import { setActiveModal } from "state/actions";

const formSchema = object().shape({
  name: string()
    .required()
    .matches(/^[aA-zZ\s]+$/, "Only alphabetic characters are allowed"),
  email: string().email().required(),
  postcode: number()
    .typeError("postcode must be a number")
    .positive("postcode must not be negative")
    .test({
      name: "postcode",
      message: "postcode must have 4 digits",
      test: (val) => {
        if (val) {
          return val.toString().length === 4;
        }
        return false;
      },
    })
    .required(),
  feedback: string()
    .required()
    .matches(/^[\w.'\s]+$/, "Special characters are not allowed"),
  recaptcha: string().required(),
});

const initialValues = {
  name: "",
  email: "",
  postcode: "",
  feedback: "",
  recaptcha: "",
};

const ContactUsForm = (props) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);

  const buttonText = props.buttonText || "Submit";

  const recaptchaRef = React.createRef();
  const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY;

  const feedbackFormApiUrl = process.env.GATSBY_FEEDBACK_FORM_API_URL;

  const handleSubmit = (values, actions) => {
    recaptchaRef.current.reset();
    setSending(true);
    fetch(feedbackFormApiUrl, {
      method: "POST",
      body: JSON.stringify(values),
    })
      .then((response) =>
        response
          .json()
          .then((body) => ({ status: response.status, body: body }))
      )
      .then((data) => {
        if (data.status === 200) {
          actions.resetForm({
            values: initialValues,
          });
          dispatch(
            setActiveModal("contact-us-thank-you", {
              friendlyId: data.body.friendly_id,
            })
          );
        } else {
          dispatch(setActiveModal("contact-us-error"));
        }
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <Formik
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
        isValid,
        dirty,
      }) => {
        return (
          <>
            <div className="p-2">
              <h6 className="text-dark font-weight-bold">
                Help us build a shared vision and sustainable future for the
                City of Logan.
              </h6>
              <p className="text-dark" style={{ fontWeight: 500 }}>
                Use this form to provide feedback on our ideas for Logan Plan
                2025. If you can’t find what you need, let us know and we’ll do
                our best answer your question. You can also{" "}
                <a
                  href="https://confirmsubscription.com/h/d/36B90EA256980D95"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sign up
                </a>{" "}
                to our mailing list to receive updates.
              </p>
            </div>
            <Form
              style={props.style}
              className={props.formClassName}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="name">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="postcode">
                <Form.Label>Postcode *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your postcode"
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  isInvalid={!!errors.postcode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postcode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  required
                  name="feedback"
                  value={values.feedback}
                  onChange={handleChange}
                  isInvalid={!!errors.feedback}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.feedback}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="small">
                <p className="m-0">* This field is mandatory</p>
                <p>
                  Council collects personal information in order to provide
                  services and information. It may be used to update records,
                  contact you about Council business, and can only be accessed
                  by employees and authorised contractors. All information is
                  handled in accordance with Council’s{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.logan.qld.gov.au/information-and-privacy/privacy"
                  >
                    Privacy Policy and Procedure
                  </a>
                  .
                </p>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaSiteKey}
                onChange={(response) => {
                  setFieldValue("recaptcha", response);
                }}
              />
              <Button
                type="submit"
                disabled={!dirty || !isValid}
                {...props.buttonProps}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "150px", height: "40px" }}
                >
                  {sending ? (
                    <>
                      <Spinner animation="border" size="24" />
                      <span className="ml-2 font-weight-bold">
                        {buttonText}ing...
                      </span>
                    </>
                  ) : (
                    <span className="ml-2 font-weight-bold">{buttonText}</span>
                  )}
                </div>
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

ContactUsForm.propTypes = {
  address: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
  buttonText: PropTypes.string,
  formClassName: PropTypes.string,
};

export default ContactUsForm;
