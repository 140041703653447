import React from "react";

import PropTypes from "prop-types";

import DocLink from "components/Links/DocLink";

const SectionText = ({ text, className, docLink }) => {
  const linkComponent = () => {
    if (docLink && docLink.requiredDocument) {
      const { requiredDocument, linkText } = docLink;
      return <DocLink requiredDocument={requiredDocument}>{linkText}</DocLink>;
    }
    return null;
  };

  return text.map((content, index) => {
    return (
      <div key={index} className={className}>
        <p>{content}</p>
        <p>{linkComponent()}</p>
      </div>
    );
  });
};

SectionText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  docLink: PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    requiredDocument: PropTypes.string.isRequired,
  }),
};

export default SectionText;
