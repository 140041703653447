import React, { useState, useCallback } from "react";

import debounce from "debounce-promise";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import Async from "react-select/async";

import { setPropertyParameters } from "state/actions";

import PropertyTooltip from "./PropertyTooltip";

const searchUrl = process.env.GATSBY_DEVET_PROPERTY_SEARCH_URL;

const Input = (props) => {
  return <components.Input {...props} isHidden={false} />;
};

const PropertySearch = () => {
  const dispatch = useDispatch();
  const defaultPlaceholder =
    "Search properties (enter address or plan/lot or key)";
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
  const [inputValue, setInputValue] = useState("");
  const selectedProperty = useSelector((state) => state.selectedProperty);

  const formatLabel = (addressData) => {
    return `${addressData.propertyFmtAddress} | ${addressData.propertyLotPlan} | ${addressData.propertyKey}`;
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const onChange = (option) => {
    dispatch(setPropertyParameters(option));
    setInputValue(option ? formatLabel(option) : "");
  };

  const onFocus = () => {
    setPlaceholder("Start typing to find properties...");
  };

  const onBlur = () => {
    setPlaceholder(defaultPlaceholder);
  };

  const searchProperties = (val) => {
    if (val.length > 4) {
      return fetch(`${searchUrl}${val.replace("/", "_")}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((response) => {
          if (Array.isArray(response)) {
            return response || [];
          }
        });
    }
  };

  const debounceSearchProperties = useCallback(
    debounce(searchProperties, 300, {
      leading: true,
    }),
    []
  );

  const customStyles = {
    input: (provided, state) => {
      if (state.hasValue) {
        provided.gridTemplateColumns = "fit-content(100%)";
      }
      provided.color = "#003f75";
      return provided;
    },
    option: (provided) => ({
      ...provided,
      color: "#003f75",
    }),
    control: (provided) => ({
      ...provided,
      color: "#003f75",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#003f75",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#003f75",
    }),
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center flex-nowrap">
        <div className="flex-grow-1">
          <Async
            styles={customStyles}
            placeholder={placeholder}
            isClearable
            value={selectedProperty}
            inputValue={inputValue}
            getOptionValue={(e) => e.propertyKey}
            getOptionLabel={(e) => formatLabel(e)}
            loadOptions={debounceSearchProperties}
            onInputChange={onInputChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            components={{ Input }}
          />
        </div>
        <div className="ml-2">
          <PropertyTooltip />
        </div>
      </Row>
      <Row className="small text-dark mt-1 pl-2">
        e.g. 150 Wembley Road LOGAN CENTRAL QLD 4114 OR Lot 8 RP 219577 OR
        194449
      </Row>
    </Container>
  );
};

export default PropertySearch;
