import React from "react";

import { QuestionCircleFill } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const PropertyTooltip = (props) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id="property-search-tooltip" {...props}>
        Type at least 5 characters of the address, lot and plan or property key
        for a list of suggestions
      </Tooltip>
    }
  >
    <QuestionCircleFill color="#003f75" size={28} />
  </OverlayTrigger>
);

export default PropertyTooltip;
