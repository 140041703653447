import React from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

const SectionHeading = (props) => {
  const HeaderTag = `h${props.heading.priority}`;

  const hasSeparator = !_isEmpty(props.separator);
  const hasSubheading = !_isEmpty(props.subheading);
  const hasMultipleSubheadings = hasSubheading
    ? Array.isArray(props.subheading.text)
    : false;

  var subheadingContent = null;

  if (hasSubheading && !hasMultipleSubheadings) {
    subheadingContent = (
      <p style={props.subheading.style}>{props.subheading.text}</p>
    );
  } else if (hasMultipleSubheadings) {
    subheadingContent = props.subheading.text.map((paragraph, index) => (
      <p key={index} style={props.subheading.style}>
        {paragraph}
      </p>
    ));
  }

  return (
    <div
      className={
        props.alignCenter ? "d-flex align-items-center flex-column" : ""
      }
    >
      <HeaderTag id={props.id} style={props.heading.style}>
        {props.heading.text}
      </HeaderTag>
      {hasSeparator ? <hr style={props.separator.style} /> : null}
      {subheadingContent}
    </div>
  );
};

SectionHeading.propTypes = {
  id: PropTypes.string,
  alignCenter: PropTypes.bool,
  heading: PropTypes.shape({
    priority: PropTypes.number.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
  }).isRequired,
  separator: PropTypes.shape({
    style: PropTypes.object,
  }),
  subheading: PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    style: PropTypes.object,
  }),
};

export default SectionHeading;
